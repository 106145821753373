import Vue from 'vue';
import Vuex from 'vuex';
import api from '@pre_app/api';
import input from './modules/input';
import calculations from './modules/calculations';
import location from './modules/location';
import merchant from './modules/merchant';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    extVars: {},
    params: {},
    errorCode: null,
    manifestGuid: null,
    merchantGuid: null,
    locationGuid: null,
    dataLoading: false,
    captchaToken: null
  },

  mutations: {
    setExtVars(state, extVars) {
      state.extVars = extVars;
    },

    setCaptchaToken(state, captchaToken) {
      state.captchaToken = captchaToken;
    },

    setErrorCode(state, errorCode) {
      state.errorCode = errorCode;
    },

    setGuids(state, { manifestGuid, merchantGuid, locationGuid }) {
      if (manifestGuid) state.manifestGuid = manifestGuid;
      if (merchantGuid) state.merchantGuid = merchantGuid;
      if (locationGuid) state.locationGuid = locationGuid;
    },

    setParams(state, params) {
      state.params = params;
    },

    setDataLoading(state, loading) {
      state.dataLoading = loading;
    }
  },

  actions: {
    setParamsFromUrl({ commit, getters }) {
      const searchParams = new URLSearchParams(window.location.search);

      commit('setParams', Object.fromEntries(searchParams));

      if (getters.noGuids) {
        commit('setGuids', {
          manifestGuid: searchParams.get('manifest_guid'),
          merchantGuid: searchParams.get('merchant_guid'),
          locationGuid: searchParams.get('location_guid')
        });
      }
    },

    async fetchMerchantAndLocationGuids({ commit }, manifestGuid) {
      const { data } = await api.manifests.fetchOne(manifestGuid);

      commit('setGuids', {
        merchantGuid: data.manifest.merchant_guid,
        locationGuid: data.manifest.location_guid
      });
    },

    clearErrorCode({ commit }) {
      commit('setErrorCode', null);
    }
  },

  getters: {
    noGuids(state) {
      return !state.manifestGuid && !state.merchantGuid && !state.locationGuid;
    }
  },

  modules: {
    input,
    calculations,
    location,
    merchant
  }
});
