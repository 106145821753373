export default axios => ({
  fetchOne(locationGuid) {
    return axios.get(`/locations/${locationGuid}`);
  },

  fetchAllByMerchant(merchantGuid) {
    return axios.get('/locations', {
      params: { merchant_guid: merchantGuid }
    });
  }
});
