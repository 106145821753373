import api from '@pre_app/api';
import { COMBINED_FLOW_STATES } from '@pre_app/utils/constants';

const state = () => ({
  stateCode: null,
  guid: null,
});

const mutations = {
  setStateCode(state, stateCode) {
    state.stateCode = stateCode;
  },

  setGuid(state, guid) {
    state.guid = guid;
  }
};

const actions = {
  async fetchLocation({ commit }, locationGuid) {
    // don't fetch same location if already cached
    if(this.state.location.guid === locationGuid) {
      return;
    }
    const { data } = await api.locations.fetchOne(locationGuid);

    commit('setStateCode', data.location.state_code);
    commit('setGuid', locationGuid);
    commit('merchant/setDba', data.location.dba, { root: true });
    commit('merchant/setCountryCode', data.location.country_code, { root: true });
    commit('setGuids', { locationGuid: data.location.guid }, { root: true });
  }
};

const getters = {
  isCombinedFlow(state) {
    return COMBINED_FLOW_STATES.includes(state.stateCode);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
