// Required for adding polyfills for IE 11
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from '@pre_app/App';
import i18n from '@pre_app/i18n';
import router from '@pre_app/router';
import store from '@pre_app/store';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBan, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCreditCard, faTag } from '@fortawesome/pro-solid-svg-icons';
import { faScaleBalanced } from '@fortawesome/pro-duotone-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome';

library.add(faBan, faQuestionCircle, faCreditCard, faScaleBalanced, faTag);

Vue.component('App', App);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('FontAwesomeLayers', FontAwesomeLayers);

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    i18n,
    router,
    store
  }).$mount('[data-behavior="pre-applications"]');
});
