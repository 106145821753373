<template>
  <div id="pre-application">
    <div class="pre-application-content">
      <alert-banner />
      <router-view />
    </div>
  </div>
</template>

<script>
import AlertBanner from './components/shared/AlertBanner';

export default {
  components: {
    AlertBanner
  },

  props: {
    extVars: {
      type: Object,
      default: () => {}
    }
  },

  created() {
    this.$store.commit('setExtVars', this.extVars);
    this.$store.dispatch('setParamsFromUrl');
  }
};
</script>

<!-- Do not use scoped because it will break .grecaptcha-badge on children -->
<style lang="scss">
#pre-application {
  display: flex;
  flex-direction: column;
  align-items: center;

  .pre-application-content {
    max-width: 440px;
    min-width: 300px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
</style>
