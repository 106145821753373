import api from '@pre_app/api';

class MissingLocationsError extends Error {
  constructor(message, responseObj) {
    super(message);
    this.name = 'MissingLocationsError';
    this.response = responseObj;
  }
}

const state = () => ({
  dba: null,
  countryCode: 'US',
  locations: []
});

const mutations = {
  setDba(state, dba) {
    state.dba = dba;
  },

  setCountryCode(state, countryCode) {
    state.countryCode = countryCode;
  },

  setLocations(state, locations) {
    state.locations = locations;
  }
};

const actions = {
  async fetchMerchant({ commit }, merchantGuid) {
    const { data } = await api.merchants.fetchOne(merchantGuid);

    commit('setDba', data.merchant.dba);
    commit('setCountryCode', data.merchant.country_code);
  },

  async fetchLocations({ commit }, merchantGuid) {
    const { data } = await api.locations.fetchAllByMerchant(merchantGuid);

    if (data.locations.length < 1) {
      throw new MissingLocationsError('missing_locations', {
        data: { error_code: 'missing_locations' }
      });
    }

    commit('setLocations', data.locations);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
