/* eslint-disable max-len */

export default {
  errors: {
    somethingWrong: 'Parece que hubo un error.',
    tryOrContact:
      'Por favor de intentar de nuevo o {modalLink} para pedir ayuda.',
    tryOrVisit: 'Por favor de intentar de nuevo o ve {url}.',
    missingMerchant:
      'Lo sentimos. Este enlace de la solicitud no está asociado con un comerciante minorista. Comuníquese con el comerciante minorista con el que estuvo en contacto o {modalLink} para que un representante lo asista.',
    recaptchaError:
      'No se puede cargar el recaptcha. Por favor actualice su navegador e inténtelo de nuevo.',
    linkText: 'ponerse en contacto con el servicio al cliente'
  },
  buttons: {
    getStarted: 'Empezar',
    preparing: 'Preparando...'
  },
  EducationModal: {
    modalHeader: {
      heading: '¿Por qué alquilar con Acima?',
      text: 'Creemos que un cliente informado es nuestro mejor cliente.'
    },
    item1: {
      heading: 'No somos un préstamo ni una tarjeta de crédito',
      text: 'Con un contrato de alquiler de Acima, usted no está comprando hoy. Acima es la propietaria del artículo arrendado hasta que usted realice todos los pagos de renovación del arrendamiento. Si no utiliza una opción de compra anticipada, puede terminar pagando más del doble del precio de venta al público. Siempre tienes la opción de comprar anticipadamente y ser propietario por menos.',
      estimateCosts: 'Calcule los costes.'
    },
    item2: {
      heading: 'Puede comprar anticipadamente y ahorrar',
      text: 'Siempre tiene la flexibilidad de nuestra "opción de compra anticipada". Cuando esté preparado, pague una suma global y sea propietario inmediatamente. Cuanto antes lo haga, más ahorrará.'
    },
    item3: {
      heading: 'Transparencia con nuestros minoristas',
      text: 'Confiamos en que nuestros minoristas compartan información sobre Acima como alternativa a las opciones de crédito al consumo. Aunque el arrendamiento con opción a compra puede costar más que las ofertas de crédito disponibles, nuestros bajos pagos y la flexibilidad inigualable pueden ser lo más adecuado para su vida y su presupuesto. A veces incentivamos a nuestros minoristas anfitriones para que den a conocer nuestra propuesta de valor. Pregunte a su vendedor por todas las opciones de crédito y arrendamiento.'
    }
  },
  EstimatePage: {
    headerTitle: 'Estimar los costos de arrendamiento',
    headerText:
      'Utilice la herramienta de estimados para repasar los costos de arrendamiento antes de presentar su solicitud. La información de costo real se basa en la aprobación de la solicitud.'
  },
  EstimateSummaryPage: {
    headerTitle: 'Estimado de arrendamiento'
  },
  CostOfLeaseSummaryBox: {
    headerText1: 'Entendiendo el Costo de Alquiler',
    headerSubText1:
      'Esto es una estimación de lo que pagará si realiza todos los pagos de renovación de arrendamiento opcionales durante 12 meses.',
    headerText2: 'Qué es un "Arrendamiento con opción de Compra"?',
    headerSubText2:
      'Un arrendamiento es diferente que una tarjeta de crédito o préstamo. Por ejemplo, en lugar de intereses, cobramos un costo de alquiler además del precio en efectivo del comerciante minorista. Acima es propietario de la propiedad arrendada. Los pagos de arrendamiento se retiran automáticamente de la cuenta que proporcionó como parte del proceso de originacion del arrendamiento.',
    buttonText: 'Vea cómo puede ahorrar'
  },
  CostOfLeaseSummaryFooter: {
    p1: '*Los estimados son solo para fines ilustrativos. El impuesto aplicableno está incluido. Los términos de arrendamiento completos se proporcionan en el contrato de arrendamiento para los solicitantes aprobados. Solo con comerciante minoristas participantes. Adquirir la propiedad mediante un contrato de arrendamiento cuesta más que el precio en efectivo del comerciante minorista.'
  },
  EpoBox: {
    headerText: 'Opciones de compra anticipada',
    headerSubText:
      'Arrastre el control deslizante para explorar cómo puede ahorrar.',
    buttonText: 'Continuar a la aplicación'
  },
  EpoSlider: {
    days: 'Dias',
    months: 'Meses'
  },
  EpoSummaryTable: {
    costOfLeaseService: 'Costo de los servicios de arrendamiento',
    totalOfPayments: 'Total de pagos'
  },
  EstimateFormBox: {
    skipToApplicationLink: 'Saltar a la aplicación',
    buttonText: 'Ver estimado'
  },
  EstimateSummaryBox: {
    headerText: 'Estimado de arrendamiento con opción a compra de 12 meses',
    headerSubText:
      'Esto es un estimado de lo que pagará si realiza todos los pagos de renovación opcionales de arrendamiento.'
  },
  EstimateSummaryFooter: {
    p1: 'Los estimados son solo para fines ilustrativos. El impuesto aplicableno está incluido. Los términos de arrendamiento completos se proporcionan en el contrato de arrendamiento para los solicitantes aprobados. Solo con comerciante minoristas participantes. Adquirir la propiedad mediante un contrato de arrendamiento cuesta más que el precio en efectivo del comerciante minorista. Algunos acuerdos pueden contener una cuota de procesamiento. Esta cuota no se acredita como pago de alquiler y no se aplica a ninguna opción de compra ni al total de pagos.',
    p2: 'Opciones de compra anticipada: el acuerdo  estándar ofrece 12 meses para comprar. No está inscrito automáticamente en las opciones de compra anticipada. Para ejercer una opción de compra anticipada, llame al {phone} o visite {url} después de recibir la propiedad arrendada.'
  },
  EstimateSummaryTable: {
    itemPrice: 'Precio del articulo',
    costOfLeaseService: 'Costo de los servicios de arrendamiento',
    totalOfPayments: 'Total de pagos',
    initialPayment: 'Pago inicial',
    paymentAmount: 'Monto del pago',
    numberOfPayments: 'Numero de pagos',
    processingFee: 'Cuota de Procesamiento',
    initialRentPayment: 'Pago Inicial de Alquile',
    tooltips: {
      itemPrice:
        'Este es el costo que pagaría si le pagara al comerciante minorista hoy.',
      initialPayment:
        'Puede que se requiera un pago inicial según los términos de aprobación. Este pago be realizarse cuando se firme el contrato.',
      paymentAmount:
        'Para comprar con anticipación, puede hacer pagos adicionales o acordar montos de pago mayores que se adapten a sus necesidades.',
      totalOfPayments:
        'Si no ejerce una opción de compra anticipada, puede terminar pagando más del doble del precio en efectivo del comerciante minorista.'
    }
  },
  FrequencyButton: {
    weekly: 'Semanal',
    every_other_week: 'Quincenal otra semana',
    twice_monthly: 'Dos veces al mes',
    monthly: 'Una vez al mes'
  },
  FrequencyMenu: {
    label: '¿Con qué frecuencia quiere pagar?'
  },
  GetStartedFormBox: {
    headerText: 'Empieze!',
    headerSubText:
      'Utilice la herramienta de estimación para revisar los costos de alquiler. La información de costo real se basa en la aprobación de la solicitud.',
    buttonText: 'Continuar'
  },
  GooglePolicy: {
    notice:
      'Este sitio está protegido por reCAPTCHA y se aplican {privacyUrl} y {termsUrl} de Google.',
    privacy: 'la Política de Privacidad',
    terms: 'los Términos de Servicio'
  },
  ItemPriceInput: {
    label: '¿Cuál es el precio de los artículos?',
    validationErrors: {
      minValue: 'La cantidad debe ser superior a $100',
      maxValue: 'La cantidad no puede ser superior a $5,500'
    }
  },
  ProgressBar: {
    step: 'Paso'
  },
  SaveMoneyBox: {
    headerText: 'Cómo puede ahorrar dinero?',
    headerSubText:
      'Arrastre el control deslizante para explorar cómo puede ahorrar.',
    buttonText: 'Iniciar Aplicación'
  },
  SelectLocationBox: {
    headerText: 'Selecciona una ubicación',
    headerSubText: 'Díganos en qué tienda planea comprar.',
    defaultOptionText: 'Selecciona una ubicación',
    buttonText: 'Continuar'
  }
};

/* eslint-enable max-len */
