const state = () => ({
  itemPrice: null,
  itemPriceValid: false,
  frequency: null
});

const mutations = {
  setItemPrice(state, itemPrice) {
    state.itemPrice = itemPrice;
  },

  setItemPriceValid(state, valid) {
    state.itemPriceValid = valid;
  },

  setFrequency(state, frequency) {
    state.frequency = frequency;
  }
};

const getters = {
  inputValid(state) {
    return state.itemPriceValid && state.frequency;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
