<template>
  <error-message
    v-if="$store.state.errorCode"
    :error-code="$store.state.errorCode"
  />
</template>
<script>
import ErrorMessage from './ErrorMessage';

export default {
  components: {
    ErrorMessage
  }
};
</script>

<style>
@media screen and (max-width: 440px) {
  .alert-banner {
    margin: 20px;
  }
}
</style>
