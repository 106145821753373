import Vue from 'vue';
import Router from 'vue-router';
import {
  selectLocationPageGuard,
  educationPageGuard,
  estimateSummaryPageGuard,
  combinedFlowHomePageGuard,
  estimatePageGuard,
} from './guards';

Vue.use(Router);

function loadPage(view) {
  return () => import(`@pre_app/pages/${view}.vue`);
}

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: loadPage('HomePage')
    },
    {
      path: '/select_location',
      name: 'SelectLocationPage',
      component: loadPage('SelectLocationPage'),
      beforeEnter: selectLocationPageGuard
    },
    {
      path: '/about_leasing',
      name: 'EducationPage',
      component: loadPage('EducationPage'),
      beforeEnter: educationPageGuard
    },
    {
      path: '/estimate',
      name: 'EstimatePage',
      component: loadPage('EstimatePage'),
      beforeEnter: estimatePageGuard
    },
    {
      path: '/estimate_summary',
      name: 'EstimateSummaryPage',
      component: loadPage('EstimateSummaryPage'),
      beforeEnter: estimateSummaryPageGuard
    },
    {
      path: '/full',
      name: 'CombinedFlowHomePage',
      component: loadPage('combined_flow/CombinedFlowHomePage'),
      redirect: { name: 'GetStartedPage' },
      beforeEnter: combinedFlowHomePageGuard,
      children: [
        {
          path: 'get_started',
          name: 'GetStartedPage',
          component: loadPage('combined_flow/GetStartedPage'),
          meta: { step: 1 }
        },
        {
          path: 'cost_of_lease',
          name: 'CostOfLeasePage',
          component: loadPage('combined_flow/CostOfLeasePage'),
          meta: { step: 2 }
        },
        {
          path: 'save_money',
          name: 'SaveMoneyPage',
          component: loadPage('combined_flow/SaveMoneyPage'),
          meta: { step: 3 }
        }
      ]
    },
    {
      path: '*',
      component: loadPage('HomePage')
    }
  ]
});

export default router;
