/* eslint-disable max-len */

export default {
  errors: {
    somethingWrong: 'Looks like something went wrong.',
    tryOrContact: 'Please try again or {modalLink} for help.',
    tryOrVisit: 'Please try again or visit {url}.',
    missingMerchant:
      "We're sorry. This application link is not associated with a retailer. Please contact the retailer you were in contact with or {modalLink} to have a representative assist you.",
    recaptchaError:
      'Unable to load recaptcha. Please refresh your browser and try again.',
    linkText: 'contact customer service'
  },
  buttons: {
    getStarted: 'Get Started',
    preparing: 'Preparing...'
  },
  EducationModal: {
    modalHeader: {
      heading: 'Why lease with Acima?',
      text: 'We believe an informed customer is our best customer.'
    },
    item1: {
      heading: "We're not a loan or credit card",
      text: "With an Acima lease, you aren't buying today. Acima owns the leased item until you make all the lease renewal payments. If you do not use an early purchase option, you may pay more than double the retail price. You always have the option to purchase early and own it for less.",
      estimateCosts: 'Estimate costs.'
    },
    item2: {
      heading: 'You can purchase early & save',
      text: 'You always have the flexibility of our "early purchase option." When you\'re ready, pay a lump sum and own immediately. The sooner you do, the more you save!'
    },
    item3: {
      heading: 'Transparency with our retailers',
      text: 'We rely on our retailers to share information about Acima as an alternative to consumer credit options. Even though lease-to-own may cost more than credit offers available to you, our low payments and unmatched flexibility may be the right fit for your life and your budget. We sometimes incentivize our host retailers to increase awareness of our value proposition. Ask your salesperson about all of your credit and leasing options.'
    },
    ca: {
      item1: {
        text: "With an Acima lease, you aren't buying today. Acima owns the leased item until you make all the lease renewal payments. You always have the option to purchase early and own it for less.",
      },
      item2: {
        text: 'You always have the flexibility to purchase your lease early. When you\'re ready, pay a lump sum and own immediately. The sooner you do, the more you save!'
      }
    }
  },
  EstimatePage: {
    headerTitle: 'Estimate Lease Costs',
    headerText:
      'Use the estimating tool to review leasing costs before applying. Actual cost information is based on application approval.'
  },
  EstimateSummaryPage: {
    headerTitle: 'Lease Estimate'
  },
  CostOfLeaseSummaryBox: {
    headerText1: 'Understanding the Cost of Leasing',
    headerSubText1:
      'This is an estimate of what you will pay if you make all optional lease renewal payments for 12 months.*',
    headerText2: 'What is "Lease-To-Own"?',
    headerSubText2:
      'A lease is different from a credit card or a loan. For example instead of interest we charge a leasing cost on top of the retail price. Acima owns the lease items. Lease payments are automatically withdrawn from the account you provide as part of the lease creation process.',
    buttonText: 'See How You Can Save'
  },
  CostOfLeaseSummaryFooter: {
    p1:
      '*Estimates for illustrative purposes only. Applicable tax not included. Full lease terms are provided in the lease agreement to approved applicants. Merchant participating locations only. ' +
      "Acquiring ownership by leasing costs more than the retailer's cash " +
      'price.'
  },
  EpoBox: {
    headerText: 'Early Purchase Options',
    headerSubText: 'Drag the slider to explore how you can save.',
    buttonText: 'Continue to Application'
  },
  EpoSlider: {
    days: 'Days',
    months: 'Months'
  },
  EpoSummaryTable: {
    costOfLeaseService: 'Cost of Lease Service',
    totalOfPayments: 'Total of Payments'
  },
  EstimateFormBox: {
    skipToApplicationLink: 'Skip to Application',
    buttonText: 'See Estimate'
  },
  EstimateSummaryBox: {
    headerText: '12 Month Lease-to-Own Estimate',
    headerSubText:
      'This is an estimate of what you will pay if you make all optional lease renewal payments.'
  },
  EstimateSummaryFooter: {
    p1: "Estimates for illustrative purposes only. Applicable tax not included. Full lease terms are provided in the lease agreement to approved applicants. Retailer participating locations only. Acquiring ownership by leasing costs more than the retailer's cash price. Some agreements may contain a processing fee. This fee is not credited as a rent payment and does not apply to any purchase option or the total of payments.",
    p2: 'Early Purchase Options: Standard agreement offers 12 months to ownership. You are not automatically enrolled in early purchase options. To use an early purchase option call {phone} or go to {url} after you receive the lease property.'
  },
  EstimateSummaryTable: {
    itemPrice: 'Item Price',
    costOfLeaseService: 'Cost of Lease Service',
    totalOfPayments: 'Total of Payments',
    initialPayment: 'Initial Payment',
    paymentAmount: 'Payment Amount',
    numberOfPayments: 'Number of Payments',
    processingFee: 'Processing Fee',
    initialRentPayment: 'Initial Rent Payment',
    tooltips: {
      itemPrice:
        'This is the cost you would pay if you paid the merchant today.',
      initialPayment:
        'An initial payment may be required based on approval terms. This payment is due at signing.',
      paymentAmount:
        'To purchase early you may make additional payments, or arrange for increased payment amounts to fit your needs.',
      totalOfPayments:
        'If you do not use an early purchase option, you may pay more than double the retail price.'
    }
  },
  FrequencyButton: {
    weekly: 'Weekly',
    every_other_week: 'Every Other Week',
    twice_monthly: 'Twice Monthly',
    monthly: 'Monthly'
  },
  FrequencyMenu: {
    label: 'How often do you want to pay?'
  },
  GetStartedFormBox: {
    headerText: 'Get Started!',
    headerSubText:
      'Use the estimating tool to review leasing costs. Actual cost information is based on application approval.',
    buttonText: 'Continue'
  },
  GooglePolicy: {
    notice:
      'This site is protected by reCAPTCHA and the Google {privacyUrl} and {termsUrl} apply',
    privacy: 'Privacy Policy',
    terms: 'Terms of Service'
  },
  ItemPriceInput: {
    label: 'What is the price of the item(s)?',
    validationErrors: {
      minValue: 'Amount must be at least $100',
      maxValue: "Amount can't be more than $5,500"
    }
  },
  ProgressBar: {
    step: 'Step'
  },
  SaveMoneyBox: {
    headerText: 'How Can You Save Money?',
    headerSubText: 'Drag the slider to explore how you can save.',
    buttonText: 'Start Application'
  },
  SelectLocationBox: {
    headerText: 'Select a Location',
    headerSubText: 'Tell us which store you are planning to shop at.',
    defaultOptionText: 'Select a location',
    buttonText: 'Continue'
  }
};

/* eslint-enable max-len */
