import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLeases from '@pre_app/i18n/leases/en';
import esLeases from '@pre_app/i18n/leases/es';

Vue.use(VueI18n);

const messages = {
  'en.leases': { leases: enLeases },
  'es.leases': { leases: esLeases }
};

const searchParams = new URLSearchParams(window.location.search);
const locale = searchParams.get('lang') || 'en.leases';

export default new VueI18n({
  locale,
  messages
});
