<template>
  <div class="alert alert-danger">
    <div v-if="merchantMissing">
      <i18n path="leases.errors.missingMerchant">
        <template #modalLink>
          <a href="#" onclick="$('#contact-details-modal').modal('show')">{{
            $t('leases.errors.linkText')
          }}</a>
        </template>
      </i18n>
    </div>

    <div v-else-if="recaptchaError">
      {{ $t('leases.errors.recaptchaError') }}
      <i18n path="leases.errors.tryOrContact">
        <template #modalLink>
          <a href="#" onclick="$('#contact-details-modal').modal('show')">{{
            $t('leases.errors.linkText')
          }}</a>
        </template>
      </i18n>
    </div>

    <div v-else-if="tryOrVisit">
      {{ $t('leases.errors.somethingWrong') }}
      <i18n path="leases.errors.tryOrVisit">
        <template #url>
          <a href="https://www.acima.com/find-a-store" target="_blank">
            https://www.acima.com/find-a-store
          </a>
        </template>
      </i18n>
    </div>

    <div v-else>
      {{ $t('leases.errors.somethingWrong') }}
      <i18n path="leases.errors.tryOrContact">
        <template #modalLink>
          <a href="#" onclick="$('#contact-details-modal').modal('show')">{{
            $t('leases.errors.linkText')
          }}</a>
        </template>
      </i18n>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    errorCode: {
      type: String,
      required: true
    }
  },

  computed: {
    merchantMissing() {
      return this.errorCode === 'missing_merchant_error';
    },

    recaptchaError() {
      return this.errorCode === 'recaptcha_error';
    },

    tryOrVisit() {
      if (
        this.errorCode === 'missing_guids' ||
        this.errorCode === 'missing_locations' ||
        this.errorCode.match(/not_found/)
      ) {
        return true;
      }

      return false;
    }
  }
};
</script>
