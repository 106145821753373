export default axios => ({
  post(params) {
    return axios.post('/manifests.json', params);
  },

  fetchOne(manifestGuid) {
    return axios.get(`/manifests/${manifestGuid}`, {
      params: { manifest_guid: manifestGuid }
    });
  }
});
