import axios from 'axios';
import qs from 'qs';
import calculations from './repos/calculations';
import locations from './repos/locations';
import manifests from './repos/manifests';
import merchants from './repos/merchants';
import { setLocale, setCsrfToken } from './interceptors/request';

const instance = axios.create({
  paramsSerializer: params => qs.stringify(params)
});

instance.interceptors.request.use(config => {
  config = setLocale(config);
  config = setCsrfToken(config);

  return config;
});

export default {
  calculations: calculations(instance),
  locations: locations(instance),
  manifests: manifests(instance),
  merchants: merchants(instance)
};
