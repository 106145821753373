import store from '@pre_app/store';

export function selectLocationPageGuard(_to, _from, next) {
  if (store.state.merchant.locations.length < 2) {
    return next({ name: 'HomePage' });
  }

  return next();
}

export function educationPageGuard(_to, _from, next) {
  if (!store.state.location.stateCode) {
    return next({ name: 'HomePage' });
  }

  return next();
}

export function estimateSummaryPageGuard(_to, _from, next) {
  if (!store.getters['input/inputValid']) {
    return next({ name: 'EstimatePage' });
  }

  return next();
}

export function combinedFlowHomePageGuard(_to, _from, next) {
  if (!store.state.merchant.dba || !store.state.location.stateCode) {
    return next({ name: 'HomePage' });
  }

  return next();
}

export function estimatePageGuard(_to, _from, next) {
  if (store.state.merchant.country_code == 'CA') {
    return next({ name: 'HomePage' });
  }

  return next();
}
