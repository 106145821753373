import i18n from '@pre_app/i18n';

const setLocale = config => {
  config.data = { ...config.data, lang: i18n.locale };

  return config;
};

const setCsrfToken = config => {
  const { content } = document.querySelector('meta[name="csrf-token"]');

  config.headers['X-CSRF-Token'] = content;

  return config;
};

export { setLocale, setCsrfToken };
