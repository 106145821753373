import api from '@pre_app/api';

const state = () => ({
  defaultMonth: 3,
  summary: {},
  payoffScale: [],
  epo: {},
  areFresh: false
});

const mutations = {
  setSummary(state, summary) {
    state.summary = summary;
  },

  setPayoffScale(state, payoffScale) {
    state.payoffScale = payoffScale;
  },

  setEpo(state, month) {
    const epo = state.payoffScale.find(data => data.month === month);

    state.epo = epo;
  },

  setAreFresh(state, value) {
    state.areFresh = value;
  }
};

const actions = {
  async fetchCalculations({ commit, state }, params) {
    const { data } = await api.calculations.post(params);

    commit('setSummary', data.calculations.summary);
    commit('setAreFresh', true);
    commit('setPayoffScale', data.calculations.lease_cost_scale);
    commit('setEpo', state.defaultMonth);
  }
};

const getters = {
  months(state) {
    return state.payoffScale.map(data => data.month);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
